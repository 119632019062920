<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Store</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0 row">
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.clients"
              clearable
              outlined
              dense
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-autocomplete
              @change="onCountryChange"
              v-model="formData.country_id"
              label="Country"
              item-text="text"
              item-value="index"
              :items="serverData.countries"
              clearable
              outlined
              dense
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-autocomplete
              @change="onStateChange"
              :disabled="isStatesLoaded"
              v-model="formData.state_id"
              label="State"
              item-text="title"
              item-value="id"
              :items="serverData.states"
              clearable
              outlined
              dense
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-autocomplete
              @change="onCityChange"
              :disabled="isCitiesLoaded"
              v-model="formData.city_id"
              label="City"
              item-text="title"
              item-value="id"
              :items="serverData.cities"
              clearable
              outlined
              dense
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-autocomplete
              :disabled="isAreasLoaded"
              v-model="formData.area_id"
              label="Area"
              item-text="title"
              item-value="id"
              :items="serverData.areas"
              clearable
              outlined
              dense
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-autocomplete
              v-model="formData.location_id"
              label="Location"
              item-text="text"
              item-value="index"
              :items="serverData.locations"
              clearable
              outlined
              dense
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.supplier_code"
              label="Store Code"
              clearable
              outlined
              dense
              :error-messages="supplier_codeErrors"
              @input="$v.formData.supplier_code.$touch()"
              @blur="$v.formData.supplier_code.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.store_name"
              label="Store Name"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-autocomplete
              v-model="formData.brands"
              label="Brands"
              item-text="text"
              item-value="index"
              :items="serverData.brands"
              clearable
              outlined
              dense
              multiple
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.contact_name"
              label="Contact Name"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.phone"
              label="Phone"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.mobile"
              label="Mobile"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.address"
              label="Adress"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.remarks"
              label="Remarks"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.instructions"
              label="Instructions"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      supplier_code: { required },
      customer_id: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      customer_id: null,
      supplier_code: null,
      store_name: null,
      contact_name: null,
      phone: null,
      mobile: null,
      address: null,
      area_id: null,
      city_id: null,
      state_id: null,
      country_id: null,
      remarks: null,
      instructions: null,
      location_id: null,
      brands: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/warehouse_management/client_stores/create")
        .then((response) => {
          this.serverData = response.data;
          this.serverData.states = null;
          this.serverData.cities = null;
          this.serverData.areas = null;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      // let data = new FormData();
      // Object.entries(this.formData).forEach((item) => {
      //   if ((item[1] && item[0] != "image") || item[1] == 0) {
      //     data.append(item[0], item[1]);
      //   } else if (item[1] && item[0] == "image") {
      //     data.append(item[0], item[1][0]);
      //   }
      // });
      let data = this.formData;
      // let date = { ...this.formData }.received_time;
      // this.formData.received_time = `${date.getTime() / 1000}`;
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          // let data = JSON.stringify(this.entCreate);
          let data = this.convertToFormData();
          // console.log("data to send is ", data);
          ApiService.post("/warehouse_management/client_stores/store", data)
            .then(() => {
              Swal.fire({
                title: "Created",
                text: `Courier Account has been successfully added!`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.resetCreateForm();
              this.toggleModal();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        customer_id: null,
        supplier_code: null,
        store_name: null,
        contact_name: null,
        phone: null,
        mobile: null,
        address: null,
        area_id: null,
        city_id: null,
        state_id: null,
        country_id: null,
        remarks: null,
        instructions: null,
        location_id: null,
        brands: null,
      };
    },
    onCountryChange(val) {
      // console.log("country", val);
      if (val) {
        this.pageLoader(true);
        let data = { country: val };
        ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.loader(false);

            this.dialog = false;
          });
      }
    },
    onStateChange(val) {
      // console.log("state", val);
      if (val) {
        this.pageLoader(true);
        let data = { state: val };
        ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.loader(false);
            this.dialog = false;
          });
      }
    },
    onCityChange(val) {
      // console.log("city", val);
      if (val) {
        this.pageLoader(true);
        let data = { city: val };
        ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.loader(false);
            this.dialog = false;
          });
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    supplier_codeErrors: function () {
      return this.handleFormValidation("supplier_code", this);
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
  },
};
</script>
